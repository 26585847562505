/* eslint-disable default-case */
import React, { Fragment, useReducer, useEffect, useCallback } from "react";
import { useAuth0, user } from "@auth0/auth0-react";
import ai from "../../helpers/axios";

import TextInput from "../text-input";
import CreatableSelect from "react-select/creatable";
import Select from "react-select";

import { useParams, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import QuotePreview from "./quote-preview";
import Dropzone from "../shared/dropzone";

console.log("outside of function?");

const reducer = (state, action) => {
  switch (action.type) {
    case "dataBeganDownloading": {
      return {
        ...state,
        loading: true,
      };
    }
    case "fieldChanged": {
      return {
        ...state,
        [action.field]: action.value,
      };
    }
    case "dataDownloaded": {
      return {
        ...state,
        quote: action.payload.data,
        emailPreview: action.payload.emailPreview,
      };
    }
    default:
      break;
  }

  return state;
};

const SingleQuote = (props) => {
  const { id } = useParams();
  const history = useHistory();
  const { user } = useAuth0();

  const initialState = {
    loading: true,
    emailPreview: {
      template: "",
    },
    quote: {
      cc: [],
      bcc: [],
      email: [],
      bccSender: true,
      leadData: {
        assigneeName: "",
        assigneeId: "",
      },
    },
    testEmailInputValue: "",
    testEmails: [],
    attachments: [],
    teamMembers: [],
    sendAs: {
      label: `${user.name} <${user.email}>`,
      value: user.email,
      id: user.sub,
    },
    sendAsDefault: {
      label: "",
      value: "",
      id: "",
    },
  };

  const { getAccessTokenSilently, loginWithRedirect } = useAuth0();

  const [state, dispatch] = useReducer(reducer, initialState);

  const getData = async () => {
    dispatch({ type: "dataBeganDownloading" });

    const token = await getAccessTokenSilently().catch((e) => {
      console.error(e);
      return loginWithRedirect();
    });
    let emailPreview;

    let response = await ai
      .auth(token)
      .get(`/api/quotes/${id}`)
      .catch((error) => {
        history.push({ pathname: "/quotes" });

        setTimeout(() => {
          toast.error("Unauthorized!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        }, 50);

        return console.error(error.response);
      });

    // console.log(response.data);

    // if (!response || !response.data) {
    //   // setTimeout(() => {
    //   //   toast.error("Unauthorized!", {
    //   //     position: "top-right",
    //   //     autoClose: 5000,
    //   //     hideProgressBar: false,
    //   //     closeOnClick: true,
    //   //     pauseOnHover: true,
    //   //     draggable: true,
    //   //   });
    //   // }, 50);
    //   // return history.push({ pathname: "/quotes" });
    // }

    if (response && response.data) {
      emailPreview = await ai
        .auth(token)
        .get(`/api/quotes/${id}/emailPreview?sendAs=${state.sendAs.id}`)
        .catch((error) => {
          console.error(error);
          return;
        });

      await getTeamMembers(response.data);

      if (emailPreview) {
        emailPreview = emailPreview.data;

        dispatch({
          type: "dataDownloaded",
          payload: {
            data: response.data,
            emailPreview: emailPreview,
          },
        });
      }

      console.log("get data running");
    }
  };

  useEffect(() => {
    getData();
    // getTeamMembers();
    document.title = "Preview Quote | Flight Deck by evoJets";
  }, []);

  useEffect(() => {
    getEmailPreview();
  }, [state.sendAs.id]);

  const getEmailPreview = async () => {
    const token = await getAccessTokenSilently().catch((e) => {
      console.error(e);
      return loginWithRedirect();
    });

    let { data } = await ai.auth(token).get(`/api/quotes/${id}`);
    let { data: emailPreview } = await ai
      .auth(token)
      .get(`/api/quotes/${id}/emailPreview?sendAs=${state.sendAs.id}`);

    console.log(data);

    dispatch({
      type: "fieldChanged",
      field: "emailPreview",
      value: emailPreview,
    });

    console.log("get emailPreviewRunning running");
  };

  const getTeamMembers = async (quoteData) => {
    const token = await getAccessTokenSilently().catch((e) => {
      console.error(e);
      return loginWithRedirect();
    });

    let { data } = await ai.auth(token).get(`/api/users/getTeamMembers`);

    dispatch({
      type: "fieldChanged",
      field: "teamMembers",
      value: data,
    });

    console.log("TEAM", data);

    if (
      user["https://app.evojets.com/roles"].includes("Sales Assistant") ||
      user["https://app.evojets.com/roles"].includes("Dynamic Sales Assistant")
    ) {
      console.log("STATE", quoteData.leadData.assigneeId);

      //for DSA, remove the other users as options to send from

      let assignee = data.filter(
        (item) => item.user_id === quoteData.leadData.assigneeId
      );

      if (assignee.length) {
        if (assignee?.user_id !== user.sub) {
          dispatch({
            type: "fieldChanged",
            field: "teamMembers",
            value: assignee,
          });
        }

        dispatch({
          type: "fieldChanged",
          field: "sendAs",
          value: {
            label: `${assignee[0].name} <${assignee[0].email}>`,
            value: assignee[0].email,
            id: assignee[0].user_id,
          },
        });

        // console.log("ASSIGNEE", assignee.user_id,)
      }
    }
  };

  const emailComponents = {
    DropdownIndicator: null,
  };

  const createOption = (label) => ({
    label,
    value: label,
  });

  const handleTestEmailChange = (newValue, arg2, arg3, arg4) => {
    dispatch({
      type: "fieldChanged",
      field: "testEmails",
      value: newValue || [],
    });
  };

  const handleTestEmailInputChange = (inputValue, action) => {
    dispatch({
      type: "fieldChanged",
      field: "testEmailInputValue",
      value: inputValue,
    });

    if (
      action.action === "menu-close" &&
      validateEmail(state.testEmailInputValue)
    ) {
      dispatch({
        type: "fieldChanged",
        field: "testEmails",
        value: [...state.testEmails, createOption(state.testEmailInputValue)],
      });

      dispatch({
        type: "fieldChanged",
        field: "testEmailInputValue",
        value: "",
      });
    }

    console.log("Input Change", inputValue);
    console.log("Test Email Change arg2", action);
  };

  const validateEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const handleTestEmailKeyDown = (event) => {
    const { testEmailInputValue, testEmails } = state;
    if (!testEmailInputValue || !validateEmail(testEmailInputValue)) return;

    switch (event.key) {
      case "Enter":
      case "Tab":
        dispatch({
          type: "fieldChanged",
          field: "testEmails",
          value: [...testEmails, createOption(testEmailInputValue)],
        });

        dispatch({
          type: "fieldChanged",
          field: "testEmailInputValue",
          value: "",
        });

        event.preventDefault();
    }
  };

  const returnToEditor = (event) => {
    event.preventDefault();
    history.push({
      pathname: `/quotes/${state.quote._id}/edit`,
    });
  };

  const sendTestEmail = async (event) => {
    event.preventDefault();
    console.log(state.testEmails);

    const token = await getAccessTokenSilently().catch((e) => {
      console.error(e);
      return loginWithRedirect();
    });

    let formData = new FormData();
    let recipients = state.testEmails.map((item) => item.value);

    formData.append("recipients", JSON.stringify(recipients));

    for (const file of state.attachments) {
      formData.append("attachments[]", file);
    }

    formData.append("sendAs", JSON.stringify(state.sendAs));

    let response = await ai
      .auth(token)({
        method: "post",
        url: `/api/quotes/${id}/sendTestEmail`,
        data: formData,
      })
      .catch((error) => {
        console.error(error.response.data);

        toast.error(`${error.response.data}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });

        return;

        // return history.push({ path: "/quotes" });
      });

    if (response) {
      toast.success("Test Email Sent!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });

      document
        .getElementById("nav-container")
        .scrollIntoView({ behavior: "smooth" });

      console.log(response.data);
    }
  };

  const getSenderOptions = () => {
    let senderOptions = [
      {
        label: `${user.name} <${user.email}>`,
        value: user.email,
        id: user.sub,
      },
    ];

    if (state.teamMembers.length) {
      state.teamMembers.map((member) => {
        let formattedMember = {
          label: `${member.name} <${member.email}>`,
          value: member.email,
          id: member.user_id,
        };
        senderOptions.push(formattedMember);
      });
    }

    return senderOptions;
  };

  const sendLiveEmail = async (event) => {
    event.preventDefault();
    console.log(state.testEmails);
    const token = await getAccessTokenSilently().catch((e) => {
      console.error(e);
      return loginWithRedirect();
    });

    let formData = new FormData();
    for (const file of state.attachments) {
      formData.append("attachments[]", file);
    }

    formData.append("sendAs", JSON.stringify(state.sendAs));

    // let { data } = await ai.auth(token).post(`/api/quotes/${id}/sendLiveQuote`);

    let response = await ai
      .auth(token)({
        method: "post",
        url: `/api/quotes/${id}/sendLiveQuote`,
        data: formData,
      })
      .catch((error) => {
        console.error(error.response.data);

        toast.error(`${error.response.data}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });

        // return;
        // return history.push({ path: "/quotes" });
      });

    if (response) {
      toast.success("Live Quote Sent!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });

      console.log("live quote response", response.data);

      document
        .getElementById("nav-container")
        .scrollIntoView({ behavior: "smooth" });

      history.push("/leads");
    }
  };

  const bccList = () => {
    let bcc = false;
    if (state.quote?.bccSender) {
      bcc = `${state.sendAs.label}`;
    }
    if (state.quote?.bcc?.length) {
      bcc += ", " + state?.quote?.bcc?.join(", ");
    }

    console.log("BCC", state.quote?.bcc);
    return bcc;
  };

  const syncToAppOffer = async (event) => {
    event.preventDefault();

    const token = await getAccessTokenSilently().catch((e) => {
      console.error(e);
      return loginWithRedirect();
    });

    try {
      const response = await ai
        .auth(token)
        .post(`/api/quotes/${id}/syncToAppOffer`);

      if (response.data.success) {
        toast.success("Quote synchronized to app successfully!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });

        // Refresh data to show updated information
        getData();
      }
    } catch (error) {
      console.error("Error syncing to app:", error);

      toast.error(
        error.response?.data?.message || "Failed to sync quote to app",
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        }
      );
    }
  };

  const isAppOfferOutOfSync = () => {
    // If there's no appOffer, it's definitely out of sync
    if (!state.quote.appOffer || !state.quote.appOffer.syncedAt) return true;

    // Check if the quote has been modified since the last sync
    // Add a 5-second buffer to account for small timing differences
    const syncedAt = new Date(state.quote.appOffer.syncedAt).getTime();
    const updatedAt = new Date(state.quote.updatedAt).getTime();

    // Consider it in sync if the quote was updated within 5 seconds of the sync
    const bufferMs = 5000; // 5 seconds in milliseconds

    return updatedAt - syncedAt > bufferMs;
  };

  return (
    <Fragment>
      <h2 id="addAirportHeading">Preview Quote</h2>
      {console.log("rendered")}
      <QuotePreview emailPreview={state.emailPreview}></QuotePreview>

      <Dropzone dispatch={dispatch} attachments={state.attachments}></Dropzone>

      {/* <pre>{JSON.stringify(state.teamMembers, null, 2)}</pre> */}

      <form id="sendTestEmail" onSubmit={sendTestEmail}>
        <div className="form-group mt20">
          <label htmlFor="email">
            <strong>Send Test Email</strong>
          </label>
          <CreatableSelect
            className="email-select"
            classNamePrefix="select"
            placeholder="Enter Email Addresses"
            components={emailComponents}
            isClearable
            isMulti
            menuIsOpen={false}
            onChange={handleTestEmailChange}
            onInputChange={handleTestEmailInputChange}
            onKeyDown={handleTestEmailKeyDown}
            inputValue={state.testEmailInputValue}
            value={state.testEmails}
            name={"testEmails"}
            inputId={"testEmails"}
          ></CreatableSelect>
        </div>

        <input type="submit" value="Send Test Email"></input>
      </form>

      <form id="sendLiveEmail" onSubmit={sendLiveEmail}>
        <div id="emailHeaders">
          <h3>Email Headers</h3>
          <p>
            <strong>To: </strong>
            {state.quote &&
              `${state.quote.firstName} ${
                state.quote.lastName
              } <${state.quote.email.join(", ")}>`}
          </p>

          {state.quote.cc.length ? (
            <p>
              <strong>Cc: </strong>
              {state.quote.cc.join(", ")}
            </p>
          ) : (
            ""
          )}

          {bccList() && (
            <p>
              <strong>Bcc: </strong>
              {bccList()}
            </p>
          )}

          <p>
            <strong>Subject: </strong>
            {state.emailPreview.subject}
          </p>
        </div>

        <div className="form-group mt20">
          <label htmlFor="sendAs-select">
            <strong>Select a Sender</strong>
          </label>

          <Select
            key={`sendAs-${state.teamMembers.length}`}
            className="sendAs-select"
            classNamePrefix="select"
            name="sendAs-select"
            value={state.sendAs}
            options={getSenderOptions()}
            placeholder="Select a Sender"
            onChange={(sender) => {
              dispatch({
                type: "fieldChanged",
                field: "sendAs",
                value: sender,
              });
            }}
            defaultValue={{
              label: state.sendAsDefault.label,
              value: state.sendAsDefault.value,
              id: state.sendAsDefault.id,
            }}
          ></Select>
        </div>
        <input type="submit" value="Send Live Quote"></input>
      </form>

      <form id="syncToAppForm" onSubmit={syncToAppOffer} className="mt-30">
        <input
          type="submit"
          value={`Save & Sync To App${
            isAppOfferOutOfSync() ? " (Out of Sync)" : ""
          }`}
          className={`sync-app-btn ${
            isAppOfferOutOfSync() ? "out-of-sync" : ""
          }`}
        />
      </form>

      <button id="returnToEditor" onClick={returnToEditor}>
        Return to Editor
      </button>

      {/* <pre>{JSON.stringify(state.sendAs, null, 2)}</pre> */}
    </Fragment>
  );
};

export default SingleQuote;
