/* eslint-disable default-case */
import React, { Fragment, useReducer, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import ai from "../../helpers/axios";
import TextInput from "../text-input";
import { useParams, useHistory, useLocation } from "react-router-dom";
import CreatableSelect from "react-select/creatable";

import { EditorState, ContentState, convertFromHTML } from "draft-js";

import { stateToHTML } from "draft-js-export-html";

import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

// leg form import
import DatePicker from "react-date-picker";
import AsyncSelect from "react-select/async";
import TextAreaInput from "../textarea-input";

import timeFormatter from "../../helpers/timeFormatter";

import { headerText, footerText } from "./default-header-footer-content";
import { toast } from "react-toastify";

import * as Sentry from "@sentry/react";

import moment from "moment-timezone";

import JSONPretty from "react-json-pretty";

const toastOptions = {
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
};

function convertUTCToLocalDate(date) {
  if (!date) {
    return date;
  }
  date = new Date(date);
  date = new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate());
  return date;
}

function convertLocalToUTCDate(date) {
  if (!date) {
    return date;
  }
  date = new Date(date);
  date = new Date(
    Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())
  );
  return date;
}

const reducer = (state, action) => {
  switch (action.type) {
    case "dataBeganDownloading": {
      return {
        ...state,
        loading: true,
      };
    }
    case "leadDataDownloaded": {
      let { firstName, lastName, email, legs, subject, roundTrip } =
        action.payload.data;

      let aircraftOptions = [...state.aircraftOptions];

      if (roundTrip) {
        aircraftOptions = aircraftOptions.map((item) => {
          item.legDurations = ["", ""];
          item.fuelStops = ["", ""];
          return item;
        });
      }

      return {
        ...state,
        firstName,
        lastName,
        email: [{ label: email, value: email }],
        legs,
        subject,
        aircraftOptions,
        leadDataDownloaded: true,
      };
    }
    case "quoteDataDownloaded": {
      let {
        firstName,
        lastName,
        email,
        legs,
        aircraftOptions,
        subject,
        cc,
        bcc,
        bccSender,
        organizationId,
        headerContent,
        footerContent,
        leadId,
      } = action.payload.data;

      email = email.map((item) => {
        return { label: item, value: item };
      });

      cc = cc.map((item) => {
        return { label: item, value: item };
      });

      bcc = bcc.map((item) => {
        return { label: item, value: item };
      });

      const headerTextHTML = convertFromHTML(headerContent);
      const headerTextState = ContentState.createFromBlockArray(
        headerTextHTML.contentBlocks,
        headerTextHTML.entityMap
      );

      const footerTextHTML = convertFromHTML(footerContent);
      const footerTextState = ContentState.createFromBlockArray(
        footerTextHTML.contentBlocks,
        footerTextHTML.entityMap
      );

      legs = legs.map((leg) => {
        console.log("DEPARTURE DATE", new Date(leg.departureDate));
        // let date = leg.departureDate.toString().split("T")[0];

        // date = moment(date).toDate();

        // console.log("QUTOE LEG", leg.departureDate, date);

        return {
          ...leg,
          departureDate: new Date(leg.departureDate),
        };
      });

      aircraftOptions = aircraftOptions.map((option) => {
        if (option.ownerApprovalRequired) {
          return {
            ...option,
            importantNotesList: [
              ...(option.importantNotesList || []),
              "Owner approval",
            ].filter((item, i, arr) => arr.indexOf(item) === i), // Remove duplicates
          };
        }
        return option;
      });

      return {
        ...state,
        firstName,
        lastName,
        email,
        legs,
        aircraftOptions,
        subject,
        cc,
        bcc,
        bccSender,
        organizationId,
        headerEditor: EditorState.createWithContent(headerTextState),
        footerEditor: EditorState.createWithContent(footerTextState),
        leadId,
      };
    }

    case "fieldChanged": {
      return {
        ...state,
        [action.field]: action.value,
      };
    }
    case "legAdded": {
      let newAircraftOptions = [...state.aircraftOptions];

      newAircraftOptions = newAircraftOptions.map((item) => {
        item.legDurations.push("");
        item.fuelStops.push("");

        return item;
      });

      return {
        ...state,
        legs: [
          ...state.legs,
          {
            departureDate: "",
            departureAirport: {
              airportName: "",
              id: "",
              code: "",
              city: "",
              localCode: "",
            },
            arrivalAirport: {
              airportName: "",
              id: "",
              code: "",
              city: "",
              localCode: "",
            },
            passengers: "",
          },
        ],
        aircraftOptions: newAircraftOptions,
      };
    }
    case "legDeleted": {
      let newLegsArray = [...state.legs];
      newLegsArray.splice(action.index, 1);
      console.log(newLegsArray);

      let aircraftOptions = [...state.aircraftOptions];

      aircraftOptions = aircraftOptions.map((item) => {
        item.legDurations.splice(action.index, 1);
        item.fuelStops.splice(action.index, 1);

        return item;
      });

      return {
        ...state,
        legs: newLegsArray,
        aircraftOptions,
      };
    }
    case "legFieldChanged": {
      let newLegsArray = [...state.legs];
      newLegsArray[action.payload.legIndex][action.payload.field] =
        action.payload.value;
      return {
        ...state,
        legs: newLegsArray,
      };
    }

    case "optionAdded": {
      return {
        ...state,
        aircraftOptions: [
          ...state.aircraftOptions,
          {
            aircraft: { id: "", yom: "", name: "", seats: "", opi: "" },
            totalCost: "",
            interiorRefurbYear: "",
            notes: "",
            legDurations: [""],
            fuelStops: [""],
            safetyStandards: [],
            amenities: [],
          },
        ],
      };
    }
    case "optionFieldChanged": {
      let newOptionsArray = [...state.aircraftOptions];
      newOptionsArray[action.payload.optionIndex][action.payload.field] =
        action.payload.value;
      return {
        ...state,
        aircraftOptions: newOptionsArray,
      };
    }

    case "flightTimesDownloaded": {
      let { index, values } = action;

      let newOptionsArray = [...state.aircraftOptions];

      let newLegDurationsArray = [
        ...newOptionsArray[action.index].legDurations,
      ];

      values.forEach((item) => {
        if (item.status === "rejected" && isNaN(item?.value?.legIndex)) {
          toast.error(
            `Flight times not available for Leg ${
              item?.value?.legIndex + 1
            }, please input manually.`,
            toastOptions
          );
          return;
        } else if (item.status === "rejected") {
          toast.error(
            `Flight times not available - please input manually.`,
            toastOptions
          );
          return;
        }

        if (
          !item?.value?.timeWithWindAdjustment ||
          !item?.value?.status ||
          isNaN(item?.value?.legIndex)
        ) {
          toast.error(
            "Flight times not available - please input manually.",
            toastOptions
          );
          return;
        }

        newLegDurationsArray[item.value.legIndex] = timeFormatter(
          item.value.timeWithWindAdjustment
        );

        newOptionsArray[index].legDurations = newLegDurationsArray;

        toast.success(
          "Flight Times were populated successfully.",
          toastOptions
        );

        return newOptionsArray;
      });

      return {
        ...state,
        aircraftOptions: newOptionsArray,
      };
    }

    case "optionCheckboxFieldChanged": {
      let newOptionsArray = [...state.aircraftOptions];
      let newCheckboxList = [
        ...newOptionsArray[action.payload.optionIndex][action.payload.field],
      ];

      newCheckboxList = new Set(newCheckboxList);

      if (action.payload.value) {
        newCheckboxList.add(action.payload.name);
      } else {
        newCheckboxList.delete(action.payload.name);
      }

      newOptionsArray[action.payload.optionIndex][action.payload.field] = [
        ...newCheckboxList,
      ];

      return {
        ...state,
        aircraftOptions: newOptionsArray,
      };
    }

    case "optionAircraftSubFieldChanged": {
      let newOptionsArray = [...state.aircraftOptions];
      newOptionsArray[action.payload.optionIndex].aircraft[
        action.payload.field
      ] = action.payload.value;
      return {
        ...state,
        aircraftOptions: newOptionsArray,
      };
    }

    case "optionDeleted": {
      let newOptionsArray = [...state.aircraftOptions];
      newOptionsArray.splice(action.index, 1);
      console.log(newOptionsArray);
      return {
        ...state,
        aircraftOptions: newOptionsArray,
      };
    }

    case "optionArrayFieldChanged": {
      let newOptionsArray = [...state.aircraftOptions];
      let newLegDurationsArray = [
        ...newOptionsArray[action.payload.optionIndex].legDurations,
      ];

      let newFuelStopsArray = [
        ...newOptionsArray[action.payload.optionIndex].fuelStops,
      ];

      if (action.payload.arrayField === "legDuration") {
        newLegDurationsArray[action.payload.legIndex] = action.payload.value;
        newOptionsArray[action.payload.optionIndex].legDurations =
          newLegDurationsArray;
      } else if (action.payload.arrayField === "fuelStop") {
        newFuelStopsArray[action.payload.legIndex] = action.payload.value;
        newOptionsArray[action.payload.optionIndex].fuelStops =
          newFuelStopsArray;
      }

      return {
        ...state,
        aircraftOptions: newOptionsArray,
      };
    }

    default:
      break;
  }
  return state;
};

const headerTextHTML = convertFromHTML(headerText);
const headerTextState = ContentState.createFromBlockArray(
  headerTextHTML.contentBlocks,
  headerTextHTML.entityMap
);

const footerTextHTML = convertFromHTML(footerText);
const footerTextState = ContentState.createFromBlockArray(
  footerTextHTML.contentBlocks,
  footerTextHTML.entityMap
);

const NewQuoteForm = (props) => {
  const { id: leadId, quoteId } = useParams();
  const history = useHistory();
  const location = useLocation();

  let sourcingRecords = [];
  let leadL2Data = null;

  if (
    location?.state?.sourcingRecords &&
    location?.state?.sourcingRecords?.length
  ) {
    sourcingRecords = location.state.sourcingRecords;
  }

  if (location?.state?.leadL2Data) {
    leadL2Data = location?.state?.leadL2Data;
  }

  // console.log("SOURCING RECORDS", sourcingRecords);
  // console.log("LEAD L2 Data", leadL2Data);

  const isNewQuote = location.pathname.includes("/quotes/new");
  const isExistingQuote = !!quoteId;

  let initialState = {};
  if (isNewQuote || isExistingQuote) {
    initialState = {
      loading: true,
      leadDataDownloaded: false,
      sourcingRecordsProcessed: false,
      firstName: "",
      lastName: "",
      email: [],
      emailInputValue: "",
      cc: [],
      ccInputValue: "",
      bcc: [],
      bccInputValue: "",
      bccSender: true,
      subject: "",
      headerEditor: EditorState.createWithContent(headerTextState),
      footerEditor: EditorState.createWithContent(footerTextState),
      aircraftOptions: [
        {
          aircraft: { id: "", yom: "", name: "", seats: "", opi: "" },
          totalCost: "",
          interiorRefurbYear: "",
          notes: "",
          fuelStops: [""],
          legDurations: [""],
          safetyStandards: [],
          amenities: [],
          ownerApprovalRequired: false,
          importantNotesList: [], // New array for important notes checkboxes
        },
      ],
      legs: [
        {
          departureDate: "",
          departureAirport: {
            airportName: "",
            id: "",
            code: "",
            city: "",
            localCode: "",
          },
          arrivalAirport: {
            airportName: "",
            id: "",
            code: "",
            city: "",
            localCode: "",
          },
          passengers: "",
        },
      ],
    };
  }

  const [state, dispatch] = useReducer(reducer, initialState);

  const { getAccessTokenSilently, loginWithRedirect, user } = useAuth0();
  let ip =
    Math.floor(Math.random() * 255) +
    1 +
    "." +
    (Math.floor(Math.random() * 255) + 0) +
    "." +
    (Math.floor(Math.random() * 255) + 0) +
    "." +
    (Math.floor(Math.random() * 255) + 0);

  useEffect(() => {
    if (document) {
      document
        .getElementById("page-wrap")
        .scrollIntoView({ behavior: "smooth" });
    }

    processSourcingRecords();
  }, [state.loading]);

  useEffect(() => {
    if (
      state?.aircraftOptions?.length &&
      state.leadDataDownloaded &&
      state.sourcingRecordsProcessed
    ) {
      state?.aircraftOptions.map((v, i) => handleFlightTimeCalculation(i));
    }
  }, [state.sourcingRecordsProcessed, state.leadDataDownloaded]);

  let aircraftTypeSortOrder = {
    "Executive Turboprop": 1,
    "Compact Light Jet": 2,
    "Light Jet": 3,
    "Midsize Jet": 4,
    "Super Midsize Jet": 5,
    "Heavy Jet": 6,
    "Ultra Long Range Jet": 7,
    "Cargo Airliner": 8,
    "Turboprop Airliner": 9,
    "Regional Airliner": 10,
    "Business Airliner": 11,
  };

  let calculateOPI = () => {};

  const processSourcingRecords = async () => {
    if (!sourcingRecords || sourcingRecords?.length === 0 || !leadL2Data) {
      return;
    }

    let completeSourcingRecords = sourcingRecords.map((record) => {
      let matchingAircraftData = leadL2Data?.sourcingAircraftData.filter(
        (sourcingAircraft) => {
          return sourcingAircraft?._id === record?.aircraft?.id;
        }
      );

      let matchingOperatorData = leadL2Data?.operatorData.filter((operator) => {
        return operator?._id === record?.operator?.id;
      });

      // console.log(
      //   "MATCHING AIRCRAFT",
      //   matchingAircraftData,
      //   record?.aircraft?.name
      // );

      if (matchingAircraftData?.length) {
        matchingAircraftData = matchingAircraftData[0];
      } else {
        matchingAircraftData = {};
      }

      if (matchingOperatorData?.length) {
        matchingOperatorData = matchingOperatorData[0];
      } else {
        matchingOperatorData = {};
      }

      record.fullAircraftData = matchingAircraftData;
      record.fullOperatorData = matchingOperatorData;

      return record;
    });

    let completeSourcingRecordsSorted = completeSourcingRecords.sort((a, b) => {
      if (a.totalClientPayment === b.totalClientPayment) {
        return (
          aircraftTypeSortOrder[a.fullAircraftData.type] -
          aircraftTypeSortOrder[b.fullAircraftData.type]
        );
      }

      return a.totalClientPayment - b.totalClientPayment;
    });

    let aircraftOptionsData = completeSourcingRecordsSorted.map((record) => {
      // TODO - import OPI Data

      let totalCost = "";
      if (record?.totalClientPayment) {
        totalCost = record?.totalClientPayment.toFixed(2);
      }

      let yom,
        interiorRefurbYear,
        notes,
        opi = "";

      if (record?.yom) {
        yom = `${record.yom}`;

        if (record?.yomIsRange && record?.yomEnd) {
          yom = `${record.yom} - ${record.yomEnd}`;
        }
      } else {
        yom = `${record.fullAircraftData?.yom}`;
      }

      if (record?.interiorRefurbYear) {
        interiorRefurbYear = `${record.interiorRefurbYear}`;

        if (
          record?.interiorRefurbYearIsRange &&
          record?.interiorRefurbYearEnd
        ) {
          interiorRefurbYear = `${record.interiorRefurbYear} - ${record.interiorRefurbYearEnd}`;
        }
      }

      if (
        record?.includeOPIOnQuote &&
        record?.fullOperatorData?.composedScore
      ) {
        opi = record?.fullOperatorData?.composedScore;
      }

      if (record?.includeNotesOnQuote && record?.notes) {
        notes = record.notes;
      }

      let amenities = [];
      let safetyStandards = [];

      if (record?.amenities?.length) {
        amenities = record.amenities.map((amenity) => {
          if (amenity === "WiFi Billed") {
            return "WiFi (adtl charges may apply)";
          }

          return amenity;
        });
      }

      if (record?.safetyStandards?.length) {
        safetyStandards = record.safetyStandards;
      }

      let option = {
        aircraft: {
          name: record?.aircraft?.name || "",
          seats:
            record?.aircraft?.seats || record?.fullAircraftData?.seats || "",
          yom,
          opi,
          id: record?.aircraft?.id,
        },
        totalCost: totalCost || 0,
        interiorRefurbYear,
        notes,
        ownerApprovalRequired: record?.ownerApproval ? true : false,
        //base data
        fuelStops: [""],
        legDurations: [""],
        safetyStandards: safetyStandards,
        amenities: amenities,
        sourcingRecordId: record?._id,
      };

      return option;
    });

    dispatch({
      type: "fieldChanged",
      field: "aircraftOptions",
      value: aircraftOptionsData,
    });

    dispatch({
      type: "fieldChanged",
      field: "sourcingRecordsProcessed",
      value: true,
    });

    console.log("SOURCING RECORDS ", completeSourcingRecords);

    // console.log("AIRCRAFT OPTIONS SORTED", aircraftOptionsData);
  };

  const getLeadData = async () => {
    const token = await getAccessTokenSilently().catch((e) => {
      console.error(e);
      return loginWithRedirect();
    });

    let response = await ai
      .auth(token)
      .get(`/api/leads/${leadId}?quoteForm=true`)
      .catch((error) => {
        console.error(error);
        Sentry.captureException(error);
      });

    if (response && response.data) {
      let estimate = response.data.estimateData[0];

      // console.log("ESTIMATE DATA", estimate);

      // console.log("DEPARTURE DATE", new Date(estimate.departureDate));

      // let offset = moment.tz("America/New_York").utcOffset();

      // let parsed = moment(estimate.departureDate).utcOffset(0, true).toDate();
      // parsed = new Date(estimate.departureDate);
      // console.log(offset, parsed, estimate.departureDate);

      if (response.data.tripType === 1 && !estimate.isMultiLeg) {
        response.data.legs = [
          {
            departureDate: convertLocalToUTCDate(estimate.departureDate),
            departureAirport: {
              airportName: estimate.departureAirportName,
              id: estimate.departureAirportId,
              code: estimate.departureAirportCode,
              city: estimate.departureAirportCity,
              localCode: estimate.departureAirportLocalCode,
            },
            arrivalAirport: {
              airportName: estimate.arrivalAirportName,
              id: estimate.arrivalAirportId,
              code: estimate.arrivalAirportCode,
              city: estimate.arrivalAirportCity,
              localCode: estimate.arrivalAirportLocalCode,
            },
            passengers: parseInt(estimate.passengerCount),
            // distance: estimate.distance,
          },
        ];

        response.data.subject = `evoJets Charter Quote | {{ departureDate }} | {{{departureAirport.city}}} ({{departureAirport.localCode}}) to {{{arrivalAirport.city}}} ({{arrivalAirport.localCode}})`;
      } else if (response.data.tripType === 2) {
        console.log("DEPARTURE DATE", new Date(estimate.departureDate));
        console.log("RETURN DATE", new Date(estimate.returnDate));

        // let offset = moment.tz("America/New_York").utcOffset();

        // let parsedDepart = moment(estimate.departureDate)
        //   .utcOffset(0, true)
        //   .toDate();

        // let parsedReturn = moment(estimate.returnDate)
        //   .utcOffset(0, true)
        //   .toDate();

        // let parsedDepart = new Date(estimate.departureDate);
        // let parsedReturn = new Date(estimate.returnDate);

        // let parsedDepart = moment(estimate.departureDate)
        //   .utcOffset(0, false)
        //   .toDate();

        // let parsedReturn = moment(estimate.returnDate)
        //   .utcOffset(0, false)
        //   .toDate();

        response.data.legs = [
          {
            departureDate: convertLocalToUTCDate(estimate.departureDate),
            departureAirport: {
              airportName: estimate.departureAirportName,
              id: estimate.departureAirportId,
              code: estimate.departureAirportCode,
              city: estimate.departureAirportCity,
              localCode: estimate.departureAirportLocalCode,
            },
            arrivalAirport: {
              airportName: estimate.arrivalAirportName,
              id: estimate.arrivalAirportId,
              code: estimate.arrivalAirportCode,
              city: estimate.arrivalAirportCity,
              localCode: estimate.arrivalAirportLocalCode,
            },
            passengers: parseInt(estimate.passengerCount),
            // distance: estimate.distance,
          },
          {
            departureDate: convertLocalToUTCDate(estimate.returnDate),
            departureAirport: {
              airportName: estimate.arrivalAirportName,
              id: estimate.arrivalAirportId,
              code: estimate.arrivalAirportCode,
              city: estimate.arrivalAirportCity,
              localCode: estimate.arrivalAirportLocalCode,
            },
            arrivalAirport: {
              airportName: estimate.departureAirportName,
              id: estimate.departureAirportId,
              code: estimate.departureAirportCode,
              city: estimate.departureAirportCity,
              localCode: estimate.departureAirportLocalCode,
            },
            passengers: parseInt(estimate.passengerCount),
            // distance: estimate.distance,
          },
        ];

        response.data.roundTrip = true;
        response.data.subject = `evoJets Charter Quote | {{ departureDate }} - {{returnDate}} | {{{departureAirport.city}}} ({{departureAirport.localCode}}) to {{{arrivalAirport.city}}} ({{arrivalAirport.localCode}})`;
      }

      if (estimate.isMultiLeg) {
        response.data.legs = estimate.rawLegData.map((leg) => {
          // let offset = moment.tz("America/New_York").utcOffset();
          // let parsedDepart = moment(leg.departureDate)
          //   .utcOffset(offset, false)
          //   .toDate();

          // let parsedDepart = new Date(leg.departureDate);

          return {
            departureDate: convertUTCToLocalDate(leg.departureDate),
            departureAirport: {
              airportName: leg.departureAirport.airportName,
              id: leg.departureAirport.id,
              code: leg.departureAirport.code,
              city: leg.departureAirport.city,
              localCode: leg.departureAirport.localCode,
            },
            arrivalAirport: {
              airportName: leg.arrivalAirport.airportName,
              id: leg.arrivalAirport.id,
              code: leg.arrivalAirport.code,
              city: leg.arrivalAirport.city,
              localCode: leg.arrivalAirport.localCode,
            },
            passengers: parseInt(leg.passengers),
            // distance: estimate.distance,
          };
        });
        response.data.subject = `evoJets Charter Quote | {{ departureDate }} - {{returnDate}} | Multileg`;
      }

      // console.log("LEAD DATA", response.data);
      dispatch({
        type: "leadDataDownloaded",
        payload: {
          data: response.data,
        },
      });
    }
  };

  const getQuoteData = async () => {
    const token = await getAccessTokenSilently().catch((e) => {
      console.error(e);
      return loginWithRedirect();
    });

    let { data } = await ai.auth(token).get(`/api/quotes/${quoteId}`);

    console.log(data);

    dispatch({
      type: "quoteDataDownloaded",
      payload: {
        data,
      },
    });
  };

  useEffect(() => {
    if (isNewQuote) {
      setTimeout(function () {
        getLeadData(leadId);
      }, 0);
    } else if (isExistingQuote) {
      getQuoteData();
    }

    document.title = "Quote Builder | Flight Deck";
  }, []);

  const validateEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const handleTextInputChange = (e) => {
    console.log(e.target.value);
    return dispatch({
      type: "fieldChanged",
      field: e.target.name,
      value: e.target.value,
    });
  };

  const handleEmailChange = (newValue, actionMeta) => {
    console.group("Email Value Changed");
    console.log(newValue);
    console.log(`action: ${actionMeta.action}`);
    dispatch({
      type: "fieldChanged",
      field: "email",
      value: newValue || [],
    });
    console.groupEnd();
  };

  const handleCcChange = (newValue, actionMeta) => {
    console.group("CC Value Changed");
    console.log(newValue);
    console.log(`action: ${actionMeta.action}`);
    dispatch({
      type: "fieldChanged",
      field: "cc",
      value: newValue || [],
    });
    console.groupEnd();
  };

  const handleBccChange = (newValue, actionMeta) => {
    console.group("BCC Value Changed");
    console.log(newValue);
    console.log(`action: ${actionMeta.action}`);
    dispatch({
      type: "fieldChanged",
      field: "bcc",
      value: newValue || [],
    });
    console.groupEnd();
  };

  const handleEmailInputChange = (inputValue, action) => {
    console.group("Email Input Changed");
    console.log(inputValue);
    console.log("action", action);

    dispatch({
      type: "fieldChanged",
      field: "emailInputValue",
      value: inputValue,
    });

    if (
      action.action === "menu-close" &&
      validateEmail(state.emailInputValue)
    ) {
      dispatch({
        type: "fieldChanged",
        field: "email",
        value: [...state.email, createOption(state.emailInputValue)],
      });
      dispatch({
        type: "fieldChanged",
        field: "emailInputValue",
        value: "",
      });
    }

    console.groupEnd();
  };

  const handleCcInputChange = (inputValue, action) => {
    console.group("CC Input Changed");
    console.log(inputValue);
    console.log("action", action);

    dispatch({
      type: "fieldChanged",
      field: "ccInputValue",
      value: inputValue,
    });

    if (action.action === "menu-close" && validateEmail(state.ccInputValue)) {
      dispatch({
        type: "fieldChanged",
        field: "cc",
        value: [...state.cc, createOption(state.ccInputValue)],
      });
      dispatch({
        type: "fieldChanged",
        field: "ccInputValue",
        value: "",
      });
    }

    console.groupEnd();
  };

  const handleBccInputChange = (inputValue, action) => {
    console.group("BCC Input Changed");
    console.log(inputValue);
    console.log("action", action);

    dispatch({
      type: "fieldChanged",
      field: "bccInputValue",
      value: inputValue,
    });

    if (action.action === "menu-close" && validateEmail(state.bccInputValue)) {
      dispatch({
        type: "fieldChanged",
        field: "bcc",
        value: [...state.bcc, createOption(state.bccInputValue)],
      });
      dispatch({
        type: "fieldChanged",
        field: "bccInputValue",
        value: "",
      });
    }

    console.groupEnd();
  };

  const emailComponents = {
    DropdownIndicator: null,
  };

  const createOption = (label) => ({
    label,
    value: label,
  });

  const handleEmailKeyDown = (event) => {
    const { emailInputValue, email } = state;
    if (!emailInputValue || validateEmail(emailInputValue)) return;

    switch (event.key) {
      case "Enter":
      case "Tab":
        console.group("Email Value Added");
        console.log(email);
        console.groupEnd();

        if (state.email) {
          dispatch({
            type: "fieldChanged",
            field: "email",
            value: [...email, createOption(emailInputValue)],
          });
        } else {
          dispatch({
            type: "fieldChanged",
            field: "email",
            value: [createOption(emailInputValue)],
          });
        }

        dispatch({
          type: "fieldChanged",
          field: "emailInputValue",
          value: "",
        });
        event.preventDefault();
    }
  };

  const handleCcKeyDown = (event) => {
    const { ccInputValue, cc } = state;
    if (!ccInputValue) return;

    switch (event.key) {
      case "Enter":
      case "Tab":
        console.group("CC Value Added");
        console.log(cc);
        console.groupEnd();
        dispatch({
          type: "fieldChanged",
          field: "cc",
          value: [...cc, createOption(ccInputValue)],
        });

        dispatch({
          type: "fieldChanged",
          field: "ccInputValue",
          value: "",
        });
        event.preventDefault();
    }
  };

  const handleBccKeyDown = (event) => {
    const { bccInputValue, bcc } = state;
    if (!bccInputValue) return;

    switch (event.key) {
      case "Enter":
      case "Tab":
        console.group("Bcc Value Added");
        console.log(bcc);
        console.groupEnd();
        dispatch({
          type: "fieldChanged",
          field: "bcc",
          value: [...bcc, createOption(bccInputValue)],
        });

        dispatch({
          type: "fieldChanged",
          field: "bccInputValue",
          value: "",
        });
        event.preventDefault();
    }
  };

  const handleAddLeg = (event) => {
    event.preventDefault();

    dispatch({ type: "legAdded" });
  };

  const handleAddOption = (event) => {
    event.preventDefault();

    dispatch({ type: "optionAdded" });
  };

  const loadAirportOptions = async (inputValue) => {
    const token = await getAccessTokenSilently().catch((e) => {
      console.error(e);
      return loginWithRedirect();
    });
    let { data } = await ai
      .auth(token)
      .get(`/api/airports/search?q=${inputValue}&ip_address=${ip}`);
    console.log(data);

    let options = [];
    if (data.length >= 1) {
      options = data.map((i) => ({
        value: i._id,
        label: i.airportName,
        code: i.code,
        city: i.city,
        localCode: i.localCode,
      }));
    }

    return options;
  };
  const loadAircraftOptions = async (inputValue) => {
    const token = await getAccessTokenSilently().catch((e) => {
      console.error(e);
      return loginWithRedirect();
    });
    let { data } = await ai
      .auth(token)
      .get(`/api/aircrafts/search?q=${inputValue}`, {
        params: {
          query: inputValue,
          page: 1,
          countPerPage: 50,
        },
      });

    if (data.aircraft.length >= 1) {
      console.log(data.aircraft);
      return data.aircraft.map((aircraft) => {
        return {
          value: aircraft._id,
          label: aircraft.name,
          id: aircraft._id,
          yom: aircraft.yom,
          name: aircraft.name,
          opi: aircraft.opi,
          seats: aircraft.seats,
          speed: aircraft.cruiseSpeed,
        };
      });
    } else {
      return [];
    }
  };

  const safetyStandards = [
    { label: "ARG/US Gold", value: false },
    { label: "ARG/US Gold+", value: false },
    { label: "ARG/US Platinum", value: false },
    { label: "Wyvern Registered", value: false },
    { label: "Wyvern Wingman", value: false },
  ];

  // const amenities = [
  //   { label: "WiFi Free", value: false },
  //   { label: "WiFi (adtl charges may apply)", value: false },
  //   { label: "Lav - Enclosed", value: false },
  //   { label: "Lav - Seat", value: false },
  //   { label: "Flight Attendant", value: false },
  //   { label: "Catering Included", value: false },
  //   { label: "No Lavatory", value: false },
  //   { label: "Single Pilot", value: false },
  //   { label: "Full Galley", value: false },
  //   { label: "Pets Allowed", value: false },
  //   { label: "Pet Cleaning Fee", value: false },
  //   { label: "No Pets", value: false },
  //   { label: "No Red Wine", value: false },
  //   { label: "Smoking", value: false },
  //   { label: "Standard Catering", value: false },
  // ];

  const amenities = [
    { label: "WiFi - Free", value: false },
    { label: "WiFi - Free (domestic)", value: false },
    { label: "WiFi - Adtl Fees", value: false },
    { label: "Cabin Attendant", value: false },
    { label: "Catering Included", value: false },
  ];

  const importantNotes = [
    // { label: "Owner approval", value: false },
    { label: "Single pilot", value: false },
    { label: "Lav seat", value: false },
    { label: "No lav", value: false },
  ];

  const handleSubmit = async (e) => {
    e.preventDefault();

    let {
      bccInputValue,
      ccInputValue,
      emailInputValue,
      email,
      cc,
      bcc,
      headerEditor,
      footerEditor,
      ...restOfData
    } = state;

    let headerContent = stateToHTML(headerEditor.getCurrentContent());
    let footerContent = stateToHTML(footerEditor.getCurrentContent());

    email = email.map(({ value }) => value);
    cc = cc.map(({ value }) => value);
    bcc = bcc.map(({ value }) => value);

    const token = await getAccessTokenSilently().catch((e) => {
      console.error(e);
      return loginWithRedirect();
    });

    let quoteData = {
      email,
      bcc,
      cc,
      ...restOfData,
      headerContent,
      footerContent,
      leadId,
    };

    console.log("QUOTE DATA", quoteData);

    if (isNewQuote) {
      let response = await ai
        .auth(token)
        .post(`/api/quotes/create`, quoteData)
        .catch((err) => {
          console.error(err.response.data);
          if (err?.response?.data && err?.response?.data.includes("Your")) {
            toast.error(err.response.data, toastOptions);

            return;
          } else {
            alert(
              "Please fill out all required fields: aircraft names for each leg, and aircraft prices for each leg. "
            );
            return;
          }
        });

      if (!response) {
        return;
      }

      let data = response.data;

      setTimeout(() => {
        toast.success("Quote Created Successfully!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }, 50);

      history.push({ pathname: `/quotes/${data._id}` });
    } else if (isExistingQuote) {
      // add api call

      quoteData.leadId = state.leadId;

      console.log("UPDATE QUOTE DATA", quoteData);

      let { data } = await ai
        .auth(token)
        .put(`/api/quotes/${quoteId}`, quoteData);

      setTimeout(() => {
        toast.success("Quote Updated Successfully!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }, 50);

      if (data) {
        console.log(data);
      }

      history.push({ pathname: `/quotes/${data._id}` });
    }
  };

  const handleFlightTimeCalculation = async (index) => {
    console.log("AIRCRAFT INDEX", state.aircraftOptions[index]);

    console.log("LEGS", state.legs);

    const token = await getAccessTokenSilently().catch((e) => {
      console.error(e);
      return loginWithRedirect();
    });

    let params = {
      legs: state.legs.map((item, index) => {
        return {
          arrivalAirportId: item.arrivalAirport.id,
          departureAirportId: item.departureAirport.id,
          legIndex: index,
        };
      }),
      aircraftId: state.aircraftOptions[index].aircraft.id,
    };

    let response = await ai
      .auth(token)
      .post(`/api/quotes/flightTimes`, params)
      .catch((err) => {
        console.error(err.response.data);
        toast.error(
          "Please fill out all required fields: ensure an aircraft is selected for this Aircraft Option, and departure and arrival airports for all legs.",
          toastOptions
        );
        return;
      });

    if (response && response.data) {
      console.log(response.data);

      dispatch({
        type: "flightTimesDownloaded",
        values: response.data,
        index: index,
      });
    }
  };

  return (
    <Fragment>
      <h2 id="newQuoteHeading">New Quote</h2>

      <form id="newQuote" onSubmit={handleSubmit}>
        <div className="two-fields">
          <TextInput
            name="firstName"
            label="First Name"
            handleChange={handleTextInputChange}
            value={state.firstName}
          ></TextInput>

          <TextInput
            name="lastName"
            label="Last Name"
            handleChange={handleTextInputChange}
            value={state.lastName}
          ></TextInput>
        </div>

        <div className="form-group mt20">
          <label htmlFor="email">Email</label>
          <CreatableSelect
            className="email-select"
            classNamePrefix="select"
            placeholder="Email"
            components={emailComponents}
            isClearable
            isMulti
            menuIsOpen={false}
            onChange={handleEmailChange}
            onInputChange={handleEmailInputChange}
            onKeyDown={handleEmailKeyDown}
            inputValue={state.emailInputValue}
            value={state.email}
            name={"email"}
            inputId={"email"}
          ></CreatableSelect>
        </div>
        <div className="form-group">
          <label htmlFor="cc">CC</label>
          <CreatableSelect
            className="email-select"
            classNamePrefix="select"
            placeholder="Cc"
            components={emailComponents}
            isClearable
            isMulti
            menuIsOpen={false}
            onChange={handleCcChange}
            onInputChange={handleCcInputChange}
            onKeyDown={handleCcKeyDown}
            inputValue={state.ccInputValue}
            value={state.cc}
            inputId={"cc"}
            name={"cc"}
          ></CreatableSelect>
        </div>

        <div className="form-group">
          <label htmlFor="bcc">BCC</label>

          <CreatableSelect
            className="email-select"
            classNamePrefix="select"
            placeholder="Bcc"
            components={emailComponents}
            isClearable
            isMulti
            menuIsOpen={false}
            onChange={handleBccChange}
            onInputChange={handleBccInputChange}
            onKeyDown={handleBccKeyDown}
            inputValue={state.bccInputValue}
            value={state.bcc}
            inputId={"bcc"}
            name={"bcc"}
          ></CreatableSelect>
        </div>

        <input
          name="bccSender"
          type="checkbox"
          checked={state.bccSender ? "checked" : ""}
          onChange={(e) =>
            dispatch({
              type: "fieldChanged",
              field: "bccSender",
              value: e.target.checked ? true : false,
            })
          }
        ></input>
        <label htmlFor="bccSender">Bcc Sender</label>

        <TextInput
          name="subject"
          label="Subject"
          handleChange={handleTextInputChange}
          value={state.subject}
          className={"subject-field"}
        ></TextInput>

        <Editor
          editorState={state.headerEditor}
          wrapperClassName="header-editor-wrapper"
          editorClassName="header-editor"
          onEditorStateChange={(editorState) =>
            dispatch({
              type: "fieldChanged",
              field: "headerEditor",
              value: editorState,
            })
          }
        />

        <div className="flight-details-container">
          <h3>Flight Details</h3>

          {state.legs.map((leg, index) => (
            <div className="flight-details-block" key={index}>
              <div className="three-fields">
                <div className="datepickerContainer">
                  <label htmlFor={`departureDate[${index}]`}>
                    Departure Date
                  </label>
                  {/* {JSON.stringify(leg.departureDate)} */}
                  <DatePicker
                    name={`departureDate[${index}]`}
                    onChange={(date) => {
                      dispatch({
                        type: "legFieldChanged",
                        payload: {
                          legIndex: index,
                          field: "departureDate",
                          value: convertLocalToUTCDate(date),
                        },
                      });
                    }}
                    value={convertUTCToLocalDate(leg.departureDate)}
                  ></DatePicker>
                </div>

                <div className="airportContainer">
                  <label htmlFor={`departureAirport[${index}]`}>
                    Departure Airport
                  </label>

                  <AsyncSelect
                    key={`departure-${leg.departureAirport.airportName}`}
                    name={`departureAirport[${index}]`}
                    className="departure-airport-select"
                    classNamePrefix="select"
                    cacheOptions
                    loadOptions={loadAirportOptions}
                    placeholder="Search Airports"
                    defaultValue={{
                      label: leg.departureAirport.airportName,
                      value: leg.departureAirport.id,
                    }}
                    // onInputChange={props.handleDepartureInputChange}
                    onChange={(selectedAirport) => {
                      dispatch({
                        type: "legFieldChanged",
                        payload: {
                          legIndex: index,
                          field: "departureAirport",
                          value: {
                            airportName: selectedAirport?.label || "",
                            id: selectedAirport?.value || "",
                            code: selectedAirport?.code || "",
                            city: selectedAirport?.city || "",
                            localCode: selectedAirport?.localCode || "",
                          },
                        },
                      });
                    }}
                  ></AsyncSelect>
                </div>

                <div className="airportContainer">
                  <label htmlFor={`departureAirport[${index}]`}>
                    Arrival Airport
                  </label>

                  <AsyncSelect
                    key={`arrival-${leg.arrivalAirport.airportName}`}
                    name={`arrivalAirport[${index}]`}
                    className="arrival-airport-select"
                    classNamePrefix="select"
                    cacheOptions
                    loadOptions={loadAirportOptions}
                    placeholder="Search Airports"
                    defaultValue={{
                      label: leg.arrivalAirport.airportName,
                      value: leg.arrivalAirport.id,
                    }}
                    // onInputChange={props.handleDepartureInputChange}
                    onChange={(selectedAirport) => {
                      dispatch({
                        type: "legFieldChanged",
                        payload: {
                          legIndex: index,
                          field: "arrivalAirport",
                          value: {
                            airportName: selectedAirport?.label || "",
                            id: selectedAirport?.value || "",
                            code: selectedAirport?.code || "",
                            city: selectedAirport?.city || "",
                            localCode: selectedAirport?.localCode || "",
                          },
                        },
                      });
                    }}
                  ></AsyncSelect>
                </div>
              </div>

              <div className="three-fields">
                <TextInput
                  type={"number"}
                  name={`passengers[${index}]`}
                  label="Passengers"
                  handleChange={(e) => {
                    dispatch({
                      type: "legFieldChanged",
                      payload: {
                        legIndex: index,
                        field: "passengers",
                        value: parseInt(e.target.value) || "",
                      },
                    });
                  }}
                  value={leg.passengers}
                ></TextInput>

                <TextInput
                  name={`departureCity[${index}]`}
                  label="Departure City"
                  handleChange={(e) => {
                    dispatch({
                      type: "legFieldChanged",
                      payload: {
                        legIndex: index,
                        field: "departureAirport",
                        value: {
                          ...leg.departureAirport,
                          city: e.target.value,
                        },
                      },
                    });
                  }}
                  value={leg.departureAirport.city}
                ></TextInput>

                <TextInput
                  name={`arrivalCity[${index}]`}
                  label="Arrival City"
                  handleChange={(e) => {
                    dispatch({
                      type: "legFieldChanged",
                      payload: {
                        legIndex: index,
                        field: "arrivalAirport",
                        value: {
                          ...leg.arrivalAirport,
                          city: e.target.value,
                        },
                      },
                    });
                  }}
                  value={leg.arrivalAirport.city}
                ></TextInput>
              </div>

              {index >= 1 && (
                <button
                  className="deleteLeg action-button"
                  onClick={(e) => {
                    e.preventDefault();
                    return dispatch({ type: "legDeleted", index: index });
                  }}
                  type="button"
                >
                  Delete Leg
                </button>
              )}
            </div>
          ))}
          <button
            id="addLeg"
            className="action-button"
            onClick={handleAddLeg}
            type="button"
          >
            Add Leg
          </button>
        </div>

        <div className="aircraft-options-container">
          <h3>Aircraft Options</h3>
          {state.aircraftOptions.map((option, index) => (
            <div
              className={`aircraft-options-block aircraft-options-block[${index}]`}
              key={index}
            >
              <div className="three-fields mb-20">
                <div className="form-group">
                  <label htmlFor={`aircraft[${index}]`}>Aircraft</label>
                  <AsyncSelect
                    name={`aircraft[${index}]`}
                    className="aircraft-select"
                    classNamePrefix="select"
                    cacheOptions
                    key={`aircraft[${index}]${option.aircraft.name}`}
                    loadOptions={loadAircraftOptions}
                    placeholder="Search Aircraft"
                    defaultValue={{
                      label: option.aircraft.name,
                      value: option.aircraft.id,
                    }}
                    // onInputChange={handleInputchange}
                    onChange={(selectedAircraft) => {
                      console.log(selectedAircraft);
                      dispatch({
                        type: "optionFieldChanged",
                        payload: {
                          optionIndex: index,
                          field: "aircraft",
                          value: {
                            name: selectedAircraft.name,
                            seats: selectedAircraft.seats,
                            yom: selectedAircraft.yom,
                            opi: selectedAircraft.opi || "",
                            id: selectedAircraft.id,
                          },
                        },
                      });
                    }}
                  ></AsyncSelect>
                </div>

                <TextInput
                  type={"number"}
                  name={`seats[${index}]`}
                  label="Seats"
                  handleChange={(e) => {
                    dispatch({
                      type: "optionAircraftSubFieldChanged",
                      payload: {
                        optionIndex: index,
                        field: "seats",
                        value: parseInt(e.target.value) || "",
                      },
                    });
                  }}
                  value={option.aircraft.seats}
                ></TextInput>

                <TextInput
                  type={"number"}
                  name={`opi[${index}]`}
                  label="OPI"
                  key={`opi[${index}]${option.aircraft.id}`}
                  handleChange={(e) => {
                    dispatch({
                      type: "optionAircraftSubFieldChanged",
                      payload: {
                        optionIndex: index,
                        field: "opi",
                        value: parseInt(e.target.value) || "",
                      },
                    });
                  }}
                  value={option.aircraft.opi || ""}
                ></TextInput>
              </div>

              <div className="three-fields mb20">
                <TextInput
                  type={"number"}
                  name={`totalCost[${index}]`}
                  label="Total Cost"
                  handleChange={(e) => {
                    dispatch({
                      type: "optionFieldChanged",
                      payload: {
                        optionIndex: index,
                        field: "totalCost",
                        value: parseInt(e.target.value) || "",
                      },
                    });
                  }}
                  value={option.totalCost}
                ></TextInput>

                <TextInput
                  type={"text"}
                  name={`interiorRefurbYear[${index}]`}
                  label="Interior Refurb Year"
                  key={`interiorRefurbYear[${index}]${option.aircraft.id}`}
                  handleChange={(e) => {
                    dispatch({
                      type: "optionFieldChanged",
                      payload: {
                        optionIndex: index,
                        field: "interiorRefurbYear",
                        value: e.target.value || "",
                      },
                    });
                  }}
                  value={option.interiorRefurbYear || ""}
                ></TextInput>

                <TextInput
                  name={`yom[${index}]`}
                  label="YOM"
                  handleChange={(e) => {
                    dispatch({
                      type: "optionAircraftSubFieldChanged",
                      payload: {
                        optionIndex: index,
                        field: "yom",
                        value: e.target.value || "",
                      },
                    });
                  }}
                  value={option.aircraft.yom}
                ></TextInput>
              </div>

              <TextAreaInput
                name={`notes[${index}]`}
                label="Additional Notes"
                handleChange={(e) => {
                  dispatch({
                    type: "optionFieldChanged",
                    payload: {
                      optionIndex: index,
                      field: "notes",
                      value: e.target.value,
                    },
                  });
                }}
                value={option.notes}
                containerClasses={"mb-20"}
              ></TextAreaInput>

              <div className="leg-duration-block">
                {state.legs.map((leg, legIndex) => {
                  return (
                    <Fragment key={`legFields[${legIndex}]`}>
                      <TextInput
                        key={`legDuration[${legIndex}]`}
                        name={`legDuration[${legIndex}]`}
                        label={`Leg ${legIndex + 1} Duration`}
                        handleChange={(e) => {
                          dispatch({
                            type: "optionArrayFieldChanged",
                            payload: {
                              optionIndex: index,
                              legIndex: legIndex,
                              value: e.target.value,
                              arrayField: "legDuration",
                            },
                          });
                        }}
                        value={option.legDurations[legIndex] || ""}
                      ></TextInput>
                      <TextInput
                        key={`fuelStop[${legIndex}]`}
                        name={`fuelStop[${legIndex}]`}
                        label={`Fuel Stops - Leg ${legIndex + 1}`}
                        handleChange={(e) => {
                          dispatch({
                            type: "optionArrayFieldChanged",
                            payload: {
                              optionIndex: index,
                              legIndex: legIndex,
                              value: parseInt(e.target.value) || "",
                              arrayField: "fuelStop",
                            },
                          });
                        }}
                        value={option.fuelStops[legIndex] || ""}
                      ></TextInput>
                    </Fragment>
                  );
                })}
              </div>

              {state.aircraftOptions[index].aircraft.id &&
                state.aircraftOptions[index].aircraft.name && (
                  <button
                    className="evo-blue base-button mb-20"
                    onClick={(e) => {
                      e.preventDefault();
                      handleFlightTimeCalculation(index);
                    }}
                  >
                    Calculate Flight Times for{" "}
                    {state.aircraftOptions[index].aircraft.name}
                  </button>
                )}

              <div className="aircraft-checkboxes">
                <div className="safety-standards-container checkbox-list">
                  <h4>Safety Rating</h4>
                  {safetyStandards.map(({ label: standard }) => {
                    return (
                      <div key={`${standard}[${index}]-key`}>
                        <input
                          type="checkbox"
                          name={`${standard}[${index}]`}
                          id={`${standard}[${index}]`}
                          onChange={(e) => {
                            dispatch({
                              type: "optionCheckboxFieldChanged",
                              payload: {
                                optionIndex: index,
                                field: "safetyStandards",
                                name: standard,
                                value: e.target.checked ? true : false,
                              },
                            });
                          }}
                          checked={
                            option?.safetyStandards?.includes(standard)
                              ? "checked"
                              : false
                          }
                        ></input>
                        <label htmlFor={`${standard}[${index}]`}>
                          {standard}
                        </label>
                      </div>
                    );
                  })}
                </div>

                <div className="amenities-container checkbox-list">
                  <h4>Amenities</h4>

                  {amenities.map(({ label: amenity }) => {
                    return (
                      <div key={`${amenity}[${index}]-key`}>
                        <input
                          type="checkbox"
                          name={amenity[index]}
                          id={`${amenity}[${index}]`}
                          onChange={(e) => {
                            dispatch({
                              type: "optionCheckboxFieldChanged",
                              payload: {
                                optionIndex: index,
                                field: "amenities",
                                name: amenity,
                                value: e.target.checked ? true : false,
                              },
                            });
                          }}
                          checked={
                            option?.amenities?.includes(amenity)
                              ? "checked"
                              : false
                          }
                        ></input>
                        <label htmlFor={`${amenity}[${index}]`}>
                          {amenity}
                        </label>
                      </div>
                    );
                  })}
                </div>

                <div className="important-notes-container checkbox-list">
                  <h4>Important Notes</h4>

                  <div key={`Owner approval[${index}]-key`}>
                    <input
                      type="checkbox"
                      name={`Owner approval[${index}]`}
                      id={`Owner approval[${index}]`}
                      onChange={(e) => {
                        // Update importantNotesList
                        dispatch({
                          type: "optionCheckboxFieldChanged",
                          payload: {
                            optionIndex: index,
                            field: "importantNotesList",
                            name: "Owner approval",
                            value: e.target.checked ? true : false,
                          },
                        });

                        // Also update the ownerApprovalRequired field for backward compatibility
                        dispatch({
                          type: "optionFieldChanged",
                          payload: {
                            optionIndex: index,
                            field: "ownerApprovalRequired",
                            value: e.target.checked,
                          },
                        });
                      }}
                      checked={
                        option?.importantNotesList?.includes(
                          "Owner approval"
                        ) || option.ownerApprovalRequired
                          ? "checked"
                          : false
                      }
                    ></input>
                    <label htmlFor={`Owner approval[${index}]`}>
                      Owner approval
                    </label>
                  </div>

                  {importantNotes.map(({ label: note }) => {
                    return (
                      <div key={`${note}[${index}]-key`}>
                        <input
                          type="checkbox"
                          name={`${note}[${index}]`}
                          id={`${note}[${index}]`}
                          onChange={(e) => {
                            dispatch({
                              type: "optionCheckboxFieldChanged",
                              payload: {
                                optionIndex: index,
                                field: "importantNotesList",
                                name: note,
                                value: e.target.checked ? true : false,
                              },
                            });
                          }}
                          checked={
                            option?.importantNotesList?.includes(note)
                              ? "checked"
                              : false
                          }
                        ></input>
                        <label htmlFor={`${note}[${index}]`}>{note}</label>
                      </div>
                    );
                  })}
                </div>
              </div>

              <button
                className="deleteOption action-button"
                onClick={(e) => {
                  e.preventDefault();
                  return dispatch({ type: "optionDeleted", index: index });
                }}
                type="button"
              >
                Delete Aircraft Option
              </button>
            </div>
          ))}

          <button
            id="addOption"
            className="action-button"
            type="button"
            onClick={handleAddOption}
          >
            Add Aircraft Option
          </button>
        </div>

        <Editor
          editorState={state.footerEditor}
          wrapperClassName="header-editor-wrapper"
          editorClassName="header-editor"
          onEditorStateChange={(editorState) =>
            dispatch({
              type: "fieldChanged",
              field: "footerEditor",
              value: editorState,
            })
          }
        />

        <input type="submit" value="Save and Preview"></input>
      </form>

      {/* <pre>{JSON.stringify(state.aircraftOptions, null, 2)}</pre> */}

      {user &&
        user["https://app.evojets.com/roles"].includes("Super Admin") && (
          <>
            {/* <JSONPretty id="json-pretty" data={user}></JSONPretty> */}
            {/* <JSONPretty id="json-pretty" data={state}></JSONPretty> */}
          </>
        )}
    </Fragment>
  );
};

export default NewQuoteForm;
