import React, { Fragment, useReducer, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import ai from "../../helpers/axios";
import { io } from "socket.io-client";

const reducer = (state, action) => {
  switch (action.type) {
    case "fieldChanged":
      return { ...state, [action.field]: action.value };
    default:
      throw new Error();
  }
};

const initialState = {
  messages: [],
};

const getInitials = (name) => {
  const parts = name.split(" ");
  if (parts.length === 1) {
    return parts[0][0];
  } else if (parts.length > 1) {
    return `${parts[0][0]}${parts[parts.length - 1][0]}`;
  }
  return "";
};

const formatMessageMetadata = (message, recordType, recordId) => {
  // Determine the message context
  let contextLabel = "";
  if (message.tripId && message.leadId) {
    // Message has both IDs
    if (recordType === "trip" && message.tripId.toString() === recordId) {
      contextLabel = "This Trip";
    } else if (
      recordType === "lead" &&
      message.leadId.toString() === recordId
    ) {
      contextLabel = "This Lead";
    } else if (recordType === "trip") {
      contextLabel = "From Lead";
    } else {
      contextLabel = "From Trip";
    }
  } else if (message.tripId && recordType === "lead") {
    contextLabel = "From Trip";
  } else if (message.leadId && recordType === "trip") {
    contextLabel = "From Lead";
  }

  return contextLabel ? ` • ${contextLabel}` : "";
};

const AppMessageList = ({
  threadId,
  latestAppMessage,
  parentDispatch,
  recordType,
}) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { getAccessTokenSilently, user, loginWithRedirect } = useAuth0();

  useEffect(() => {
    const fetchMessages = async () => {
      const token = await getAccessTokenSilently().catch((e) => {
        console.error(e);
        return loginWithRedirect();
      });

      try {
        console.log(`Fetching messages for ${recordType}/${threadId}`);
        const response = await ai
          .auth(token)
          .get(`/api/appMessages/${recordType}/${threadId}`)
          .catch((e) => {
            console.error("Error fetching messages:", e);
            return { data: [] };
          });

        // Debug the response
        console.log(
          `Received ${response.data?.length || 0} messages:`,
          response.data
        );

        dispatch({
          type: "fieldChanged",
          field: "messages",
          value: response.data || [],
        });

        // Scroll smoothly to the bottom of the message list
        const messageList = document.querySelector(".app-message-list");
        if (messageList) {
          messageList.scrollTo({
            top: messageList.scrollHeight,
            behavior: "smooth",
          });
        }
      } catch (error) {
        console.error("Error in fetchMessages:", error);
        dispatch({
          type: "fieldChanged",
          field: "messages",
          value: [],
        });
      }
    };

    fetchMessages();
  }, [threadId, latestAppMessage]);

  useEffect(() => {
    const connectToSocket = async () => {
      const token = await getAccessTokenSilently().catch((e) => {
        console.error(e);
        return loginWithRedirect();
      });

      const socket = io(`${process.env.REACT_APP_SOCKET_URL}`, {
        extraHeaders: { Authorization: `Bearer ${token}` },
      });

      socket.onAny((eventName, ...args) => {
        console.log(eventName, args);

        // Listen for new app messages specifically
        if (eventName === "newAppMessage") {
          parentDispatch({
            type: "fieldChanged",
            field: "latestAppMessage",
            value: Date.now(),
          });
        }
      });
    };

    connectToSocket();
  }, []);

  return (
    <>
      {state?.messages?.length > 0 && (
        <ul className="message-list app-message-list">
          {state.messages.map((message) => (
            <li
              key={message._id}
              className={`app-message ${
                message.sender?.appUser ? "client" : "me"
              }`}
            >
              <div className="message">
                <p>
                  <strong>{message.sender.name}:</strong> {message.contents}
                </p>
                <p className="timestamp">
                  {new Date(message.timestamp).toLocaleString()}
                  {formatMessageMetadata(message, recordType, threadId)}
                </p>
              </div>
            </li>
          ))}
        </ul>
      )}
      {state?.messages?.length === 0 && (
        <div className="empty-messages-state">
          <p>No messages yet. Start the conversation with your client.</p>
        </div>
      )}
    </>
  );
};

export default AppMessageList;
