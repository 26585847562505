import React, { Fragment, useReducer } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import ai from "../../helpers/axios";

const reducer = (state, action) => {
  switch (action.type) {
    case "fieldChanged":
      return { ...state, [action.field]: action.value };
    default:
      throw new Error();
  }
};

const initialState = {
  contents: "",
};

const AppMessageForm = ({ recordId, recordType, parentDispatch }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { getAccessTokenSilently, user, loginWithRedirect } = useAuth0();

  const handleUrgentSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    handleMessageSubmit("urgent");
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    handleMessageSubmit();
  };

  const handleMessageSubmit = async (sendType) => {
    const token = await getAccessTokenSilently().catch((e) => {
      console.error(e);
      return loginWithRedirect();
    });

    const message = {
      contents: state.contents,
      recordType: recordType,
      recordId: recordId,
    };

    if (sendType === "urgent") {
      message.sendType = "urgent";
    }

    if (!recordType || !recordId) {
      return;
    }

    let { data } = await ai
      .auth(token)
      .post(`/api/appMessages/create`, message)
      .catch((e) => console.error(e));

    // Clear the input field
    dispatch({
      type: "fieldChanged",
      field: "contents",
      value: "",
    });

    // Notify parent component to refresh message list
    parentDispatch({
      type: "rootFieldChanged",
      field: "latestAppMessage",
      value: Date.now(),
    });
  };

  const handleFieldChange = (event) => {
    dispatch({
      type: "fieldChanged",
      field: event.target.name,
      value: event.target.value,
    });
  };

  return (
    <form className="message-form app-message-form" onSubmit={handleFormSubmit}>
      <input
        type="text"
        name="contents"
        value={state.contents}
        onChange={handleFieldChange}
        placeholder="Type a message to client"
      />

      <button type="submit" className="send-btn" name="send">
        <span className="material-icons">send</span>
      </button>
    </form>
  );
};

export default AppMessageForm;
