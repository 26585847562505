import React, { Fragment, useReducer, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import ai from "../../helpers/axios";
import ReactModal from "react-modal";
import { toast } from "react-toastify";
import NumberFormat from "react-number-format";
import OperatorAutocomplete from "../opi/operator-autocomplete";
import TextAreaInput from "../textarea-input";

const toastOptions = {
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "fieldChanged": {
      return {
        ...state,
        [action.field]: action.value,
      };
    }
    case "closeModal": {
      return {
        ...state,
        showModal: false,
        operator: null,
        notes: "",
        expectedCommission: "",
      };
    }
    case "openModal": {
      return {
        ...state,
        showModal: true,
      };
    }
    default:
      return state;
  }
};

const PassthroughModule = ({ tripId, parentDispatch }) => {
  const { getAccessTokenSilently, loginWithRedirect } = useAuth0();

  const initialState = {
    showModal: false,
    operator: null,
    notes: "",
    expectedCommission: "",
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  // Add state for operator options
  const [operatorOptions, setOperatorOptions] = useState([]);

  const handlePassthroughButton = () => {
    dispatch({
      type: "openModal",
    });
  };

  const handleOperatorChange = (selectedOperator) => {
    if (selectedOperator) {
      dispatch({
        type: "fieldChanged",
        field: "operator",
        value: selectedOperator,
      });
    } else {
      resetSelection("operator");
    }
  };

  const resetSelection = (fieldName) => {
    setTimeout(() => {
      dispatch({
        type: "fieldChanged",
        field: fieldName,
        value: null,
      });
    }, 0);
  };

  const handleNotesChange = (e) => {
    dispatch({
      type: "fieldChanged",
      field: "notes",
      value: e.target.value,
    });
  };

  const handleCommissionChange = (values) => {
    dispatch({
      type: "fieldChanged",
      field: "expectedCommission",
      value: values.value,
    });
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    const token = await getAccessTokenSilently().catch((e) => {
      console.error(e);
      return loginWithRedirect();
    });

    try {
      const response = await ai.auth(token).patch(`/api/trips/${tripId}/pt`, {
        operator: state.operator,
        notes: state.notes,
        expectedCommission: state.expectedCommission,
      });

      if (response?.data) {
        toast.success(
          "Trip successfully converted to passthrough",
          toastOptions
        );
        dispatch({ type: "closeModal" });
        console.log(response.data);
        // // Refresh parent data if needed
        // if (parentDispatch) {
        //   parentDispatch({
        //     type: "fieldChanged",
        //     field: "refreshDataToken",
        //     value: Date.now(),
        //   });
        // }
      }
    } catch (error) {
      toast.error(
        error.response?.data?.message || "An error occurred",
        toastOptions
      );
    }
  };

  return (
    <div id="passthrough-module mt-20">
      <button
        onClick={handlePassthroughButton}
        className="base-button evo-blue mt-30"
      >
        Convert to Passthrough
      </button>

      <ReactModal
        isOpen={state.showModal}
        shouldCloseOnEsc={true}
        shouldCloseOnOverlayClick={true}
        onRequestClose={() => dispatch({ type: "closeModal" })}
        style={{
          overlay: {
            zIndex: 999999,
            backgroundColor: "rgb(32 53 68 / 50%)",
          },
          content: {
            width: "auto",
            border: "none",
            overflowY: "scroll",
            maxHeight: "100vh",
            minWidth: "50vw",
            borderRadius: "0",
            boxShadow: "10px 10px 91px -17px rgb(0 0 0 / 25%)",
          },
        }}
        appElement={document.getElementById("app")}
      >
        <div className="modal-header base-modal">
          <h4>Convert Trip to Passthrough</h4>
        </div>
        <div className="modal-body base-modal overflow-scroll">
          <form onSubmit={handleFormSubmit} className="w-100 base-form mt-20">
            <OperatorAutocomplete
              required
              handleChange={handleOperatorChange}
              selectedOperator={state.operator}
              label="Operator"
              placeholder="Select an Operator"
              parentDispatch={dispatch}
              defaultOperatorOptions={operatorOptions}
            />

            <TextAreaInput
              name="notes"
              label="Notes"
              value={state.notes}
              handleChange={handleNotesChange}
              required
            />

            <div className="form-group">
              <label>
                Expected Commission <span className="required">*</span>
              </label>
              <NumberFormat
                value={state.expectedCommission}
                thousandSeparator={true}
                prefix={"$"}
                onValueChange={handleCommissionChange}
                placeholder="Expected Commission"
                className="semibold-text"
                decimalScale={2}
                required
              />
            </div>

            <button className="base-button evo-blue w-100 mt-20" type="submit">
              Convert to Passthrough
            </button>
          </form>
        </div>
      </ReactModal>
    </div>
  );
};

export default PassthroughModule;
