import React, { Fragment, useReducer } from "react";
import Paper from "@mui/material/Paper";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

// Import both internal and external message components
import MessageList from "./message-list";
import MessageForm from "./message-form";
import AppMessageList from "./appMessage-list";
import AppMessageForm from "./appMessage-form";

const reducer = (state, action) => {
  switch (action.type) {
    case "fieldChanged":
      return { ...state, [action.field]: action.value };
    default:
      return state;
  }
};

const initialState = {
  activeTab: 0,
  latestMessage: null,
  latestAppMessage: null,
};

const MessageContainer = ({ recordId, recordType, superAdmin = false }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const handleTabChange = (event, newValue) => {
    dispatch({
      type: "fieldChanged",
      field: "activeTab",
      value: newValue,
    });
  };

  return (
    <Paper className="messages-container mt-30">
      <h4 className="lead-notes-heading">Messages</h4>

      <Tabs
        value={state.activeTab}
        onChange={handleTabChange}
        indicatorColor="primary"
        textColor="primary"
        centered
      >
        <Tab label="Internal Team Messages" />
        <Tab label="Client App Messages" />
      </Tabs>

      {state.activeTab === 0 && (
        <Fragment>
          <MessageList
            threadId={recordId}
            latestMessage={state.latestMessage}
            parentDispatch={dispatch}
            recordType={recordType}
          />
          <MessageForm
            recordId={recordId}
            recordType={recordType}
            parentDispatch={dispatch}
          />
        </Fragment>
      )}

      {state.activeTab === 1 && superAdmin && (
        <Fragment>
          <AppMessageList
            threadId={recordId}
            latestAppMessage={state.latestAppMessage}
            parentDispatch={dispatch}
            recordType={recordType}
          />
          <AppMessageForm
            recordId={recordId}
            recordType={recordType}
            parentDispatch={dispatch}
          />
        </Fragment>
      )}
    </Paper>
  );
};

export default MessageContainer;
