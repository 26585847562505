const headerText = `<p>Hi {{ firstName }},</p>
<p>Thanks so much for contacting evoJets - I look forward to working with you!</p>
<p>Below please see preliminary aircraft info, pricing and links to representative photos for your upcoming trip. I have included several great options that we have available for you, featuring premium equipment & 2-pilot crew.</p>`;
const footerText = `<p class="footer-section"><u><strong>Pricing Details</strong></u></p>
<p><b>Prices include all anticipated taxes and operational fees</b> (flight time, crew, segment, landing, ramp, fuel surcharge). Any changes to the requested routing, aircraft type or operational adjustments of any kind may affect the cost of your trip.</p>
<p><b>Prices are cash totals - </b>all trips require payment in advance via wire transfer in order to reserve. Credit card payment may be possible but adds a 4% processing fee to the amount quoted.</p>
<p><b>**DON'T FORGET</b> to tell us if you receive more competitive options elsewhere! We will do our best to match or beat it.</p>
<p class="footer-section"><u><strong>Aircraft Availability</strong></u></p>
<p>The above is based on current aircraft positioning and availability, which changes frequently. Quotes can typically be honored within 48hrs of release, but are never reserved or held until formally booked.</p>
<p><b>All aircraft are booked on a first-come-first-served basis!</b></p>
<p class="footer-section"><u><strong>Next Steps for Booking</strong></u></p>
<ol>
   <li>Reply to this email with any questions or if you would like to secure any of the options quoted. Contact me at any time using the information provided below.</li>
   <li>When you are ready to book, we will send you the required documents to sign along with booking and payment instructions.</li>
</ol>
Once the above is complete we will move forward with obtaining passenger information and other specific trip details (catering, ground transportation, etc).
<p></p>
<p><span style="font-weight: bold; text-decoration-line: underline;">Aircraft TBA - Generic Aircraft Bookings</span></p>
<p>Aircraft options listed as 'Aircraft TBA'&nbsp;are considered “Generic Aircraft Bookings”.</p>
<p>Any aircraft listed on the evoJets website may be provided for any Generic Aircraft Booking, insofar as the aircraft is a member of the generic class listed in the Charter Quote.&nbsp; The generic classes of aircraft and the specific types of aircraft belonging to each class are defined on the evoJets website here:&nbsp;&nbsp;<a href="https://www.evojets.com/aircraft-types/">https://www.evojets.com/aircraft-types/.</a></p>
<p>Unless otherwise specified, images and aircraft specifications provided above for Generic Aircraft Bookings are for representative purposes only, and are generalizations of the aircraft class listed.</p>
<p><b><u>Operator Performance Index (OPI)</u></b></p>
<p>The <a href="https://www.evojets.com/opi/" target="_blank">Operator Performance Index (OPI)</a> score is based on evoJets’ experience working with direct air carriers and represents the opinion of evoJets regarding the performance and capabilities of these direct air carriers. evoJets makes no representations about the safety record or certification of any direct air carrier, any pilot or any aircraft. Similarly, the OPI is not intended to, and does not, make any representations about the safety record or certification of the direct air carrier, the pilots or the aircraft. EvoJets does not audit direct air carriers, and the OPI is not based on and does not reflect any standards used by any auditing organization. Further, The OPI score is based on an assessment of the direct air carrier’s overall history of performance with evoJets and is not a guarantee of future performance on any flight.<br></p>
<p>-- 
</p>
<p>We appreciate the opportunity to work with you on this upcoming trip - please feel free to contact me directly with any questions or if you wish to move forward with any of the options quoted above.</p>`;

export { headerText, footerText };
