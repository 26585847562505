import { Link } from "react-router-dom";
import moment from "moment-timezone";
import { Fragment } from "react";

const columns = [
  {
    name: "View",
    sortable: false,
    cell: (row) => {
      return <Link to={`/trips/${row._id}`}>View</Link>;
    },
    width: "70px",
  },
  {
    name: "Created At",
    selector: "createdAt",
    sortable: true,
    width: "130px",
    cell: (row) => {
      return moment(row.createdAt).format("M/D/YY - HHmm");
    },
  },

  {
    name: "Created By",
    selector: "createdBy.name",
    sortable: true,
    width: "150px",
  },
  {
    name: "Trip #",
    selector: "tripTag",
    sortable: true,
    width: "110px",
  },

  {
    name: "Client Name",
    selector: "clientName",
    sortable: true,
    width: "150px",
    cell: (row) => {
      return row.displayClientName;
    },
  },

  {
    name: "Operator",
    selector: "operatorName",
    sortable: true,
    width: "110px",
  },
  {
    name: "Departure Date",
    selector: "departureDate", // Keep this simple for DataTable's internal use
    sortable: true,
    width: "110px",
    cell: (row) => {
      const departureDate = row?.itineraryData?.[0]?.legs?.[0]?.departureDate;
      return departureDate ? moment(departureDate).format("M/D/YY") : "-";
    },
    sortFunction: (a, b) => {
      // This is just for client-side fallback - server-side sort will take precedence
      const dateA = a?.itineraryData?.[0]?.legs?.[0]?.departureDate;
      const dateB = b?.itineraryData?.[0]?.legs?.[0]?.departureDate;
      return new Date(dateA) - new Date(dateB);
    },
  },

  {
    name: "Return Date",
    selector: "returnDate",
    sortable: true,
    width: "110px",
    cell: (row) => {
      if (row.returnDate) {
        return moment(row.returnDate).format("M/D/YY");
      }

      return "-";
    },
  },

  {
    name: "Contracts",
    selector: "contractListString",
    sortable: false,
    width: "150px",
  },

  {
    name: "OFRs",
    selector: "ofrListString",
    sortable: false,
    width: "150px",
  },

  {
    name: "Routing",
    selector: "latestRoutingString",
    sortable: false,
    width: "200px",
  },

  {
    name: "Next Penalty",
    selector: "nextPenalty.dueDate",
    sortable: true,
    width: "150px",
    cell: (row) => {
      // console.log(row.nextPenalty);
      return row.nextPenalty?.dueDate
        ? moment(row.nextPenalty.dueDate).format("M/D/YY")
        : "-";
    },
  },

  {
    name: "Stage",
    selector: "displayStage",
    sortable: true,
    width: "130px",
    cell: (row) => {
      console.log("ROW", row);
      switch (row.displayStage) {
        case "newTrip":
          return "Active";
        case "ended":
          return "Ended";
        case "canceled":
          return "Canceled";
        case "archived":
          return "Archived";
        case "closedPassthrough":
          return "Closed - Passthrough";
        default:
          return "Unknown";
      }
    },
  },

  // {
  //   name: "Leg Created At",
  //   selector: "createdAt",
  //   sortable: true,
  //   width: "230px",
  //   cell: (row) => {
  //     return moment(row.createdAt).tz("America/New_York").format("llll");
  //   },
  // },

  // {
  //   name: "Last Match Event",
  //   selector: "auditTrail",
  //   sortable: true,
  //   width: "230px",
  //   cell: (row) => {
  //     return moment(
  //       row.auditTrail
  //         .filter((item) => item.eventType === "matchAdded")
  //         .sort((a, b) => new Date(b.date) - new Date(a.date))[0].timestamp
  //     )
  //       .tz("America/New_York")
  //       .format("llll");
  //   },
  // },

  // {
  //   name: "Contains FOM Match",
  //   selector: "containsFOM",
  //   sortable: true,
  //   width: "230px",
  //   cell: (row) => {
  //     return row.containsFOM ? "Yes" : "No";
  //   },
  // },

  // {
  //   name: "Is Part of Round Trip",
  //   selector: "isPartOfRoundTrip",
  //   sortable: true,
  //   width: "230px",
  //   cell: (row) => {
  //     return row.isPartOfRoundTrip ? "Yes" : "No";
  //   },
  // },

  // {
  //   name: "Assigned To",
  //   selector: "assigneeName",
  //   sortable: true,
  //   width: "150px",
  // },

  // {
  //   name: "Assigned At",
  //   selector: "assignedAt",
  //   sortable: true,
  //   width: "200px",
  //   cell: (row) => {
  //     if (row.assignedAt) {
  //       return moment(row.assignedAt).format("llll");
  //     }

  //     return "-";
  //   },
  // },
  // {
  //   name: "First Name",
  //   selector: "firstName",
  //   sortable: true,
  //   width: "150px",
  // },
  // {
  //   name: "Last Name",
  //   selector: "lastName",
  //   sortable: true,
  //   width: "150px",
  // },
  // {
  //   name: "Phone",
  //   selector: "phone",
  //   sortable: true,
  //   width: "150px",
  // },
  // // {
  // //   name: "Phone Country Code",
  // //   selector: "phoneCountryCode",
  // //   sortable: true,
  // //   width: "220px",
  // // },
  // {
  //   name: "Email",
  //   selector: "email",
  //   sortable: true,
  //   width: "150px",
  // },
  // {
  //   name: "Comments",
  //   selector: "comments",
  //   sortable: true,
  //   width: "350px",
  // },
  // {
  //   name: "Trip Type",
  //   selector: "tripType",
  //   sortable: true,
  //   width: "150px",
  //   cell: (row) => (row.tripType === 1 ? "One Way" : "Round Trip"),
  // },

  // {
  //   name: "Return Date",
  //   selector: "estimateData[0].returnDate",
  //   sortable: true,
  //   width: "200px",
  //   cell: (row) =>
  //     row.estimateData[0].returnDate ? row.estimateData[0].returnDate : "-",
  // },

  // {
  //   name: "Departure Airport",
  //   selector: "estimateData[0].departureAirportName",
  //   sortable: true,
  //   width: "350px",
  // },

  // {
  //   name: "Arrival Airport",
  //   selector: "estimateData[0].arrivalAirportName",
  //   sortable: true,
  //   width: "350px",
  // },

  // {
  //   name: "Passengers",
  //   selector: "estimateData[0].passengerCount",
  //   sortable: true,
  //   width: "150px",
  // },
  // {
  //   name: "Company",
  //   selector: "companyName",
  //   sortable: true,
  //   width: "150px",
  // },

  // {
  //   name: "Opportunity ID",
  //   selector: "orOpportunityId",
  //   sortable: true,
  //   width: "190px",
  //   cell: (row) => (
  //     <a
  //       href={`https://app1a.outreach.io/opportunities/${row.orOpportunityId}/activity`}
  //       target="_blank"
  //     >
  //       {row.orOpportunityId}
  //     </a>
  //   ),
  // },
  // {
  //   name: "Prospect ID",
  //   selector: "orProspectId",
  //   sortable: true,
  //   width: "150px",
  //   cell: (row) => (
  //     <a
  //       href={`https://app1a.outreach.io/prospects/${row.orProspectId}/activity`}
  //       target="_blank"
  //     >
  //       {row.orProspectId}
  //     </a>
  //   ),
  // },
  // {
  //   name: "Lead Origin",
  //   selector: "leadOrigin",
  //   sortable: true,
  //   width: "150px",
  //   cell: (row) => {
  //     let formatted = "";
  //     if (row.leadOrigin === "qfs") {
  //       formatted = "Flight Deck - QFS";
  //     } else if (row.leadOrigin === "liveAPI") {
  //       formatted = "Live API";
  //     } else if (row.leadOrigin === "phoneCall") {
  //       formatted = "Phone Call";
  //     } else if (row.leadOrigin === "flightDeckInternal") {
  //       formatted = "Flight Deck - Internal";
  //     } else if (row.leadOrigin === "flightDeckExternal") {
  //       formatted = "Flight Deck - External";
  //     } else {
  //       formatted = "Unset";
  //     }
  //     return formatted;
  //   },
  // },
];

export default columns;
